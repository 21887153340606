@import "../../../assets/scss/variables";

.SliderArrows {
    position: relative;

    .sliderPager {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        bottom: 50%;
        margin-bottom: -1.5em;
        pointer-events: none;

        .arrow {
            height: 1.5em;
            width: 1.5em;
            border: 0;
            border-radius: 4em;
            font-size: 2em;
            box-shadow: $sdw--light;
            transition: $tr--fast;
            position: relative;
            pointer-events: all;

            &:active,
            &:focus {
                outline: none;
                background-color: #fff;
            }

            &:first-child {
                left: 0.5em;
                @include media-breakpoint-up(lg) {
                    left: -0.75em;
                }
            }
            &:last-child {
                right: 0.5em;
                @include media-breakpoint-up(lg) {
                    right: -0.75em;
                }
            }
        }

        .arrow:hover {
            background-color: #fff;
        }
    }
}
