@import "../../../assets/scss/variables";

.Card {
    background-color: $primary;
    color: $light;
    text-align: center;
    transition: $tr--slow;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 1em;
    cursor: pointer;
    max-width: 700px;
    margin: 1em auto 0 auto;

    @include media-breakpoint-up(md) {
        // display: block;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0;
    }

    .card__image {
        width: col(5);

        @include media-breakpoint-up(sm) {
            width: col(4);
        }

        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }

    .card__info {
        text-align: left;
        display: block;
        padding: 1em;
        flex-grow: 1;

        @include media-breakpoint-up(md) {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .card__title {
        font-family: "Gotham Narrow", sans-serif;
        display: block;
        line-height: 1.1;
        margin: 0;
        font-size: 1.3em;

        @include media-breakpoint-up(sm) {
            font-size: 1.6em;
            padding: 1em;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.375em;
            padding: 0 0.5em;
        }
    }

    .btn {
        font-size: size(14);
        font-family: "Gotham Narrow", sans-serif;
        margin: 1em 2em 0;
    }

    &:hover {
        background-color: $ui-active--dark;
        color: $light;

        .btn {
            color: $dark !important;
            background-color: $light;
        }
    }
}
