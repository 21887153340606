@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

// $enable-rounded: false;

$primary: #0c3c60;
$secondary: #242424;
$info: #0c5287;
$gray: #e3e8eb;
// $dark: #242424;
$darker: #242424;
$dark: #37474f;
$success: #44cc9e;
$light: #eceff1;
$dark-blue: #b0bec5;
$gray--dark: #6b6b6b;

$theme-almacenes: #0998a8;
$theme-consorcios: #1c7947;
$theme-consumidor: #005f99;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "info": $info,
        "gray": $gray,
        "dark": $dark,
        "success": $success,
        "light": $light,
        "dark-blue": $dark-blue,
        "gray--dark": $gray--dark,
);

$ui-active--dark: #131313;
$ui-active--light: #fff;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 1010px,
        lg: 1300px,
        xl: 1500px,
);

// Animation
$tr--fast: all 0.2s;
$tr--slow: all 0.6s;

// Details
$sdw--light: 0 1px 2px 1px rgba(black, 0.2);

// Sizes
$topnav-height: 4.5em;

// Tools
@import "utils/position";
@import "utils/typography";
@import "utils/display";
