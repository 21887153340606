@import "../../../assets/scss/variables";

// ========================================================================
// Theming
// ========================================================================
.theme-almacenes {
    .section {
        color: $theme-almacenes;
    }
}
.theme-consorcios {
    .section {
        color: $theme-consorcios;
    }
}
.theme-consumidor {
    .section {
        color: $theme-consumidor;
    }
}

// ========================================================================
// Estilos generales
// ========================================================================
.HeroContainer {
    // Parameters
    $imgOffset: 10;

    position: relative;
    background: linear-gradient(#f1ebe2, #f5f3ef);

    // Utils
    $scaleOffset: $imgOffset * 0.01 + 1;

    .info {
        position: absolute;
        z-index: 50;
        left: 0;
        top: 0;
        height: 100%;

        display: grid;
        grid-template-rows: repeat(3, 1fr);

        @include media-breakpoint-up(sm) {
            grid-template-rows: repeat(3, 1fr);
        }
    }

    // ========================================================================
    // Title
    // ========================================================================
    .container-title {
        grid-row: 2;
        text-align: center;
        align-self: flex-end;

        @include media-breakpoint-up(sm) {
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            align-self: flex-end;
        }

        * {
            line-height: 1;
        }

        .section {
            font-size: size(14);

            @include media-breakpoint-up(sm) {
                font-size: size(22);
            }
        }

        .title {
            font-size: size(34);
            color: darken($primary, 20);
            font-weight: bold;

            @include media-breakpoint-up(md) {
                font-size: size(54);
            }
        }
        .description {
            font-size: size(16);

            @include media-breakpoint-up(sm) {
                font-size: size(21);
            }
            color: $dark;
        }

        @include media-breakpoint-up(xl) {
            .title {
                font-size: size(62);
            }
        }
    }

    // ========================================================================
    // Actions
    // ========================================================================
    .actions {
        grid-row: 3;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;

        @include media-breakpoint-up(sm) {
            padding-top: 0;
            justify-content: end;
            align-items: center;
            // grid-row: 3;
        }
    }

    // ========================================================================
    // Image
    // ========================================================================
    $heighXS: 260px;
    $heighMD: 400px;
    $heightLG: 500px;
    $heightXL: 600px;

    overflow: hidden;
    position: relative;
    height: $heighXS;

    @include media-breakpoint-up(md) {
        height: $heighMD;
    }

    @include media-breakpoint-up(lg) {
        height: $heightLG;
    }

    @include media-breakpoint-up(xl) {
        height: $heightXL;
    }

    // Image
    .image {
        width: 100%;
        height: 100%;
        white-space: nowrap;

        img {
            position: absolute;
            width: auto;
            height: 100% + $imgOffset;

            @include media-breakpoint-up(sm) {
                width: 100% + $imgOffset;
                height: auto;
            }

            top: -100%;
            left: -100%;
            right: -100%;
            bottom: -100%;
            margin: auto;

            transform-origin: 50% 50%;
            animation: HeroAnimation infinite 15s linear;
        }
        // Vertical Center
        .aux {
            height: 100%;
            width: 0;
        }
    }

    @keyframes HeroAnimation {
        0% {
            transform: translateX(0) scale(1);
            opacity: 0;
        }
        5% {
            opacity: 0.7;
        }
        90% {
            opacity: 0.7;
        }
        100% {
            transform: translateX(-5%) scale($scaleOffset);
            opacity: 0;

            @include media-breakpoint-up(sm) {
                transform: translateX(10%) scale($scaleOffset);
            }
        }
    }

    // Overlay
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(0deg, rgba(#eceff1, 1), rgba(#f1ebe2, 0));
        animation: GradientAnimation 5s ease infinite;
        background-position: 100% 100%;
        background-repeat: repeat-x;

        mix-blend-mode: lighten;
    }

    @keyframes GradientAnimation {
        0% {
            background-size: 100% 100%;
        }
        50% {
            background-size: 40% 40%;
        }
        100% {
            background-size: 100% 100%;
        }
    }
}
