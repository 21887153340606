@font-face {
  font-family: 'Gotham';
  src: url('gotham/GothamBlack.woff2') format('woff2'),
      url('gotham/GothamBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('gotham/GothamBold.woff2') format('woff2'),
      url('gotham/GothamBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('gotham/GothamMedium.woff2') format('woff2'),
      url('gotham/GothamMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('gotham/Gotham-Book.woff2') format('woff2'),
      url('gotham/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Narrow';
  src: url('gotham-narrow/GothamNarrow-Black.woff2') format('woff2'),
      url('gotham-narrow/GothamNarrow-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Narrow';
  src: url('gotham-narrow/GothamNarrow-Medium.woff2') format('woff2'),
      url('gotham-narrow/GothamNarrow-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
