@import "../../../assets/scss/variables";

.Home {
    .LogoHorizontal {
        width: 100%;
        height: auto;
        margin-bottom: 1em;
        max-width: 250px;

        @include media-breakpoint-up(md) {
            max-width: 400px;
        }
    }

    .home-btn {
        font-size: 0.8em;
        display: inline-flex;
        align-items: center;
        margin-top: 2em;
        color: $primary;
        transition: all 0.2s;

        .icon {
            margin-left: 0.5em;
            font-size: 1 4em;
        }

        &:hover {
            background: $primary;
            color: $light;
        }
    }

    .slick-dots {
        bottom: 1em;
        button:before {
            color: $primary;
            font-size: 10px;
        }
    }

    .slide {
        width: 100%;
        position: relative;
        display: flex;
        color: $light;
        overflow: hidden;

        .slide__info {
            position: absolute;
            bottom: 4em;
            z-index: 5;
            left: 0;
            right: col(1);
            padding: 0 0 0 col(1);

            @include media-breakpoint-up(md) {
                left: col-md(11);
                width: col-md(10);
                right: 0;
                bottom: 6em;
                padding: 0;
                // padding: 0 col-md(1) 0 0;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: col(6);
                border-bottom: 2px solid $primary;

                @include media-breakpoint-up(md) {
                    width: col-md(10);
                    left: auto;
                    right: 0;
                    border-bottom: 3px solid $primary;
                }
            }
        }

        .slide__title {
            font-weight: bolder;
            margin-bottom: 0.5em;
            font-size: size(22);
            text-transform: uppercase;
            line-height: 1;
            padding-right: 1em;

            @include media-breakpoint-up(sm) {
                font-size: size(34);
            }
            // @include media-breakpoint-up(xl) {
            //     font-size: size(42);
            // }
        }

        .slide__actions {
            display: flex;
            justify-content: space-between;
            font-size: size(14);
            margin-bottom: 1em;

            @include media-breakpoint-up(md) {
                padding-right: col-md(2);
                margin-bottom: 0;
            }
            @include media-breakpoint-up(lg) {
                font-size: size(16);
            }

            .short-description {
                font-weight: 300;
                line-height: 1.2;
                padding-right: 1em;
                max-width: 300px;
                padding-bottom: 0.5em;

                @include media-breakpoint-up(md) {
                    max-width: 600px;
                }
            }

            .link {
                white-space: nowrap;
                // padding-left: 1em;
                margin-bottom: 1em;

                a {
                    font-size: size(15);
                    font-family: "Gotham Narrow", sans-serif;
                }
            }
        }

        .slide__bg {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center;
            // max-height: 650px;
            // position: absolute;
            position: relative;
            top: 0;
            left: 0;
            z-index: 0;
            cursor: pointer;

            // @include media-breakpoint-up(lg) {
            //     max-height: 500px;
            // }
            // @include media-breakpoint-up(xl) {
            //     max-height: 700px;
            // }
        }

        // &.slide__overlay:after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba($primary, 0.6);
        //     display: block;
        //     z-index: 0;
        //     mix-blend-mode: multiply;
        // }
    }

    // .sliderPager {
    //     position: relative;

    //     .slider__inner {
    //         position: absolute;
    //         bottom: 1.5em;
    //         // left: col(1);
    //         left: 0;
    //         text-align: center;
    //         width: 100%;
    //         pointer-events: none;

    //         @include media-breakpoint-up(md) {
    //             // left: col-md(11);
    //             bottom: 2em;
    //         }
    //     }

    //     button {
    //         background: transparent;
    //         border: none;
    //         color: $light;
    //         font-size: size(14);
    //         font-weight: lighter;
    //         opacity: 0.8;
    //         font-family: "Gotham Narrow", sans-serif;
    //         transition: color 0.6s;
    //         line-height: 1;
    //         pointer-events: all;
    //         text-shadow: 0 1px 2px rgba(black, 0.6);

    //         &:first-child {
    //             padding-left: 0;
    //         }

    //         &.arrow {
    //             font-size: 1.5em;
    //             opacity: 1;
    //             vertical-align: middle;
    //         }

    //         &.active {
    //             opacity: 1;
    //         }

    //         &:active,
    //         &:focus {
    //             outline: none;
    //             box-shadow: none;
    //             opacity: 0.9;
    //         }
    //     }

    //     a {
    //         color: #fff;
    //         opacity: 0.7;
    //     }

    //     .slick-active {
    //         font-weight: bolder;

    //         a {
    //             opacity: 1;
    //         }
    //     }
    // }

    .home-line {
        color: $darker;
        font-size: 1em;
        padding: 5em 4em 3em 4em;
        line-height: 1;
        text-align: center;

        h2 {
            text-transform: uppercase;
            font-family: "Gotham";
            font-weight: lighter;
            font-size: 1.6em;
            margin-bottom: 1em;
            color: $primary;
        }
        p {
            line-height: 1.2;
            font-size: 1.1em;
            max-width: 1400px;
            margin: 0 auto;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.4em;
            font-weight: lighter;
        }
    }
}
