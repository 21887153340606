@import "../../../assets/scss/variables";

.IconColumn {
    display: flex;

    .phone {
        font-size: size(28);
    }

    .icon {
        width: size(25);
        padding: 0.2em 0.2em 0 0;
        // margin-right: 0.2em;
        font-size: 1.5em;
        text-align: center;
        flex-basis: 2em;
    }

    .title {
        font-size: size(21);
        margin-bottom: 0.2em;
    }
}
