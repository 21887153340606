@import "../../../assets/scss/variables";

.BtnCollapse {
    .toggler {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $headings-font-family;

        .icon {
            color: #000;
            transform: rotate(90deg);
        }

        &:hover {
            // background-color: $ui-active--dark !important;
            // color: #fff !important;
            cursor: pointer;

            // .icon {
            //     color: $ui-active--light !important;
            // }
        }
    }

    .collapse-wrapper {
        overflow: hidden;
        background-color: rgba(#fff, 0.5);

        a {
            // padding-left: 1em !important;
        }

        // .icon {
        //     color: $ui-active--light !important;
        // }
    }
}
