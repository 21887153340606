@import "../../../assets/scss/variables";

.Projects {
    .projects-wrapper {
        .ProjectItem {
            max-width: 1390px;
            justify-content: space-between;
            margin-bottom: 3em;
            @include media-breakpoint-up(md) {
                display: flex;
                margin-bottom: 1em;
            }

            .projectItem__title {
                font-size: size(32);
                font-family: "Gotham Narrow", sans-serif;
                color: $primary;
                font-weight: 300;
            }

            .projectItem__body {
                max-width: 900px;
            }

            .gallery-item {
                img {
                    margin: 0 auto;
                }
            }

            .left {
                min-width: 0px;
                flex-basis: 70%;
                flex-grow: 2;
            }
            .right {
                width: 320px;
                text-align: right;
                justify-content: flex-end;
                min-width: 0px;
                flex-basis: 30%;
                flex-grow: 1;
                @include media-breakpoint-up(md) {
                    padding-left: col-md(1);
                }
            }
        }
        .project-item {
            margin-bottom: 50px;
            .project-title {
                h3 {
                    a {
                        display: block;
                        text-align: center;
                        font-weight: 500;
                        font-size: 20px;
                        color: #4e4c4c;
                        letter-spacing: 1px;
                        margin-top: 20px;
                    }
                }
            }
            .project-typeSystem{
                h4 {
                    a {
                        display: block;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 36px;
                        color: #4e4c4c;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}
