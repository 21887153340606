@import "../../../assets/scss/variables";

.Product {
    .info__btn {
        display: none !important;
        @include media-breakpoint-up(md) {
            display: flex !important;
        }
    }

    .footer {
        .btn-outline {
            display: block;
            padding: 1em;
            margin-top: 2em;

            @include media-breakpoint-up(md) {
                display: inline-block;
                margin-top: 1em;
            }
        }
    }
}
