@import "../../../assets/scss/variables";

.TitleMedium {
    color: $darker;

    .section {
        font-size: size(16);
        font-weight: 500;
    }
    .title {
        font-size: size(23);
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            white-space: pre;
        }
    }

    @include media-breakpoint-up(md) {
        .section {
            font-size: size(22);
        }
        .title {
            font-size: size(28);
        }
    }
}
