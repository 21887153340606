@import "../../../assets/scss/variables";

.Button {
    border: 0;
    padding: 1em 1.5em;
    font-size: size(16);
    font-weight: 500;
    color: #37474f;
    box-shadow: 0 3px 8px rgba(black, 0.2);
    border-radius: 6px;
    position: relative;

    @include media-breakpoint-up(md) {
        padding: 1em 2.5em;
        font-size: size(18);
    }

    &.second {
        transition: all 0.2s;
        background: darken($white, 1);

        &:hover {
            background: $white;
        }
    }

    &.first {
        color: white;
        background: transparent;

        transition: all 0.2s;
        overflow: hidden;

        &:hover {
            background: rgba(white, 0.2);
        }

        &:before {
            content: "";
            @extend %absolute-full;
            background: linear-gradient(0deg, #139fb5 10%, #00d4dc 100%);
            z-index: -1;
        }

        &:after {
            content: "";
            @extend %absolute-full;

            background: linear-gradient(45deg, rgba(white, 0) 20%, rgba(white, 0.2) 50%, rgba(white, 0) 80%);
            background-repeat: no-repeat;
            animation: GlowAnim 4s infinite linear;
        }
    }

    // ========================================================================
    // Theming
    // ========================================================================
    &.almacenes {
        &:before {
            background: linear-gradient(0deg, #139fb5 10%, #00d4dc 100%);
        }
    }
    &.consorcios {
        &:before {
            background: linear-gradient(0deg, #39a388 10%, #4fd6b4 100%);
        }
    }
    &.consumidor {
        &:before {
            background: linear-gradient(0deg, #005f99 10%, #3badf2 100%);
        }
    }

    // Animation
    @keyframes GlowAnim {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
}
