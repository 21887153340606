@import "../../../assets/scss/variables";

.NewsList {
    color: $dark;

    .list-wrapper {
        width: 100%;

        @include media-breakpoint-up(sm) {
            padding-left: $col-width-md * 2;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1764px;
            right: 0;
            position: relative;
            padding-left: auto;
            margin-right: 0;
            margin-left: auto;
        }
    }

    .NewsItem {
        background-color: $light;
        line-height: 1.2;

        @include media-breakpoint-up(xl) {
            font-size: 1.1em;
        }

        .inner {
            align-items: center;
            display: flex;
        }

        &:nth-child(even) {
            background-color: $gray;
        }

        .ni__image {
            object-fit: cover;
            align-self: flex-start;
            width: col(3.5);
            display: inline-block;
            vertical-align: top;

            @include media-breakpoint-up(sm) {
                width: col-md(5);
            }
            @include media-breakpoint-up(xl) {
                width: col-md(4);
            }
        }

        .ni__info {
            width: col(8.5);
            padding: 1em 1em 0 1em;
            display: inline-block;
            vertical-align: top;
            font-weight: 300;
            max-width: 900px;

            @include media-breakpoint-up(sm) {
                padding: 1em 2em;
                width: $col-width-md * 11;
            }
        }

        .ni__date {
            margin: 0;
            font-size: size(12);
            opacity: 0.5;
        }

        .ni__title {
            font-size: size(22);
            margin-top: 0.2em;
            line-height: 1.2;

            @include media-breakpoint-up(sm) {
                font-size: size(24);
            }
        }

        .ni__description {
            font-size: size(18);
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }

        .ni__actions {
            display: none;
            a {
                font-size: 2em;
                color: $light;
                transition: $tr--fast;
                margin: 1em;
            }

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }

        .ni__description--mobile {
            padding: 1em 1em 1.5em 2em;
            // font-weight: 300;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    }
}
