@import "../../../assets/scss/variables";

.SectionInfo {
    .flex-wrap {
        .BtnIconBox {
            flex-basis: 75%;
        }
    }

    .video-container {
        display: flex;
        justify-content: center;

        iframe {
            max-width: 100% !important;
        }
    }

    p,
    ul {
        color: $gray--dark;
    }

    h1 {
        font-size: size(28);
        color: $primary;
        text-transform: uppercase;
        font-weight: bold;
    }

    h2 {
        font-size: size(24);
        color: $primary;
    }

    h3 {
        font-size: size(18);
        font-weight: normal;
    }

    .info__inner {
        display: flex;
        flex-direction: column-reverse;
        @include media-breakpoint-up(md) {
            grid-template-columns: 9fr 1fr 8fr;
            display: grid;
        }
    }

    .info__main {
        min-width: 0px;
        display: block;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    * {
        &:active,
        &:focus {
            outline: none;
        }
    }

    .info__images {
        grid-column: 3 / 3;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        min-width: 0px;
        position: relative;

        .gallery-item {
            width: 100%;
            min-height: 50px;
            opacity: 0;
            text-align: center;
            padding: 4px;
        }

        img {
            max-height: 450px;
            object-fit: cover;
            object-position: center;
            display: inline-block;
            margin: 0.1em;
        }

        .slick-slide {
            .gallery-item {
                opacity: 1;
            }
        }

        .BtnIconBox {
            max-width: 340px;
            margin-top: 2em;
        }
    }

    .info__legend {
        text-align: center;
        line-height: 1;
        margin-top: 1em;
        font-style: italic;
        font-family: "Gotham", sans-serif;
    }
}
