.show-mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hide-mobile {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

%absolute-full {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
