@import "../../../assets/scss/variables";

.Search {
    .search-input-wrapper {
        display: flex;

        input {
            flex-basis: 70%;
            margin-right: 1em;
            padding-left: 0.5em;
        }
        button {
            flex-basis: 30%;
            font-family: "Gotham Narrow", sans-serif;
        }
    }
}
