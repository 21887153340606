@import "../../../assets/scss/variables";
@import "../../UI/Button/Button.scss";

.AreasDeNegocio {
    max-width: 1600px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
    .AreaItem {
        text-align: center;
        padding: 3em 2em;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .ImageContainer {
            img {
                background: lighten($color: $info, $amount: 70);
                display: inline-block;
                padding: 2em;
                border-radius: 2em;
                box-shadow: 0 5px 4px -4px rgba(black, 0.2);
                max-width: 160px;
                height: auto;
            }

            a {
                img {
                    transition: all 0.2s;

                    &:hover {
                        background-color: #fff;
                    }
                }
            }
        }

        h3 {
            font-size: 2em;
            font-weight: lighter;
            font-family: $headings-font-family;
            margin: 1.2em 0 auto 0;
            color: $primary;
        }

        .AreaItemBody {
            font-size: 1em;
            padding: 1em 0;
            margin: auto;
            max-width: 32em;
        }

        .NavItem {
            @extend .Button;
            background-color: $info;
            color: white;
            box-shadow: 0 5px 4px -4px rgba(black, 0.2);

            display: block;
            max-width: 20em;
            margin: auto auto 0;
            font-size: 0.9em;

            transition: 0.2s all;

            &:hover {
                background-color: lighten($color: $primary, $amount: 25);
            }

            &.disabled {
                background-color: $gray;
                color: $primary;
                padding: 0.5em 1em;
            }
        }
    }
}
