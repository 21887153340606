@import "../../../assets/scss/variables";
@import "../BtnCollapse/BtnCollapse.scss";

.InfoAccordeon {
    @extend .BtnCollapse;

    // background: linear-gradient(#f9f9f9, #eceff1);
    max-width: size(480);
    position: relative;
    margin-bottom: 0.5em;
    box-shadow: 0 4px 3px -2px rgba($primary, 0.15);

    &:before {
        content: "";
        @extend %absolute-full;
        z-index: -1;
    }

    > div {
        transition: background-color 0.2s;
    }
    &:hover {
        > div {
            background-color: rgba(white, 0.3);
        }
    }

    &:after {
        content: "";
        @extend %absolute-full;
        background: linear-gradient(#f9f9f9, #eceff1);
        z-index: -1;
    }

    // Button
    .toggler {
        font-size: size(17);
        font-weight: 500;
        color: $darker;
        // text-transform: uppercase;
        padding: 1em;

        @include media-breakpoint-up(md) {
            font-size: size(18);
        }

        .icon {
            font-size: 2em;
            color: $primary;
        }
    }

    // Info
    .collapse-wrapper {
        background: none;

        .inner {
            padding: 0 1em 1em;
        }

        p {
            line-height: 1.4;
        }
    }
}

// ========================================================================
// Theming
// ========================================================================
.theme-almacenes {
    .InfoAccordeon {
        .toggler {
            .icon {
                color: $theme-almacenes;
            }
        }
        &:after {
            background: linear-gradient(#f9f9f9, #e3edee);
        }
    }
}
.theme-consorcios {
    .InfoAccordeon {
        .toggler {
            .icon {
                color: $theme-consorcios;
            }
        }
        &:after {
            background: linear-gradient(#f9f9f9, #ecf2eb);
        }
    }
}
.theme-consumidor {
    .InfoAccordeon {
        .toggler {
            .icon {
                color: $theme-consumidor;
            }
        }
        &:after {
            background: linear-gradient(#f9f9f9, #eceff1);
        }
    }
}
