@import "../../../assets/scss/variables";

.BtnIconBox {
    box-shadow: $sdw--light;
    font-family: "Gotham", sans-serif;
    background-color: $info;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    // max-width: 350px;
    // margin: 0 auto;
    font-weight: normal;
    color: $light;
    font-size: size(16);
    padding: 2em;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        background-color: lighten($primary, 10);
    }

    @include media-breakpoint-up(md) {
        padding: size(22) size(26);
        font-size: size(14);
    }

    &.light {
        background-color: $light;
        color: $primary;

        &:hover {
            background-color: lighten($light, 10);
        }
    }

    .icon {
        opacity: 0.5;
        font-size: size(26);

        @include media-breakpoint-up(md) {
            font-size: size(21);
        }
    }
}
