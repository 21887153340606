@import '../../../assets/scss/variables';

.BtnTextIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        opacity: 1;
        color: $ui-active--dark;
    }

    &:hover {
        background-color: $ui-active--dark;
        color: #fff;

        .icon {
            color: $ui-active--light;
        }
    }
}