@import "../../../assets/scss/variables";

.ProductCategories {
    padding: 0;

    .slick-arrow:before {
        color: $primary;
        font-size: 2.5rem;
        opacity: 1;
    }

    @include media-breakpoint-down(md) {
        .slick-prev {
            top: 95%;
            left: 3em;
            z-index: 10;
        }

        .slick-next {
            top: 95%;
            right: 3em;
            z-index: 10;
        }
    }

    &.featured {
        display: block;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: stretch;
        }

        .CategoryItem {
            height: 150px;

            @include media-breakpoint-up(md) {
                height: 420px;
            }
        }
    }

    &.full {
        max-width: 1250px;
        margin: 4em auto 2em;

        .CategoryItem {
            text-align: center;
            color: $dark;
            margin: 0.4em;
            height: size(150);

            @include media-breakpoint-up(md) {
                margin: 0.8em;
                height: size(250);
            }

            .ci__title {
                font-size: size(18);
                padding: 0 0.5em;
                text-align: center;
                font-weight: 600;

                @include media-breakpoint-up(md) {
                    font-size: size(28);
                }
            }

            .ci__area {
                display: none;
            }

            &:after {
                background-color: rgba($gray, 0.45);
            }

            &.active {
                color: $light;

                &:after {
                    mix-blend-mode: multiply;
                    background-color: rgba($primary, 0.4);
                }
            }
        }

        .sliderPager {
            @include media-breakpoint-down(sm) {
                bottom: 0.5em;
            }
        }
    }

    .ci__hover {
        background-color: $light;
        border-radius: 200px;
        height: 2em;
        width: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2em;
        font-size: 1.5em;
        transition: all 0.4s;

        margin-bottom: -4em;
        opacity: 0;
        box-shadow: $sdw--light;

        &:hover {
            background: #fff;
        }

        .icon {
            color: $primary;
            font-size: 0.8em;
        }
    }

    .CategoryItem:hover {
        .ci__hover {
            opacity: 1;
            margin-bottom: -3em;
            margin-top: 1.5em;
        }
        .ci__bg {
            transform: scale(1.05);
        }
    }

    .CategoryItem {
        color: $light;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        flex: 1;
        overflow: hidden;

        // @include media-breakpoint-up(md){
        //     height: 420px;
        // }

        &:after {
            content: "";
            @extend %absolute-full;
            background-color: rgba($primary, 0.65);
            z-index: -1;
        }

        &:nth-child(even):after {
            background-color: rgba($primary, 0.63);
        }

        .ci__area {
            font-size: size(22);
            margin-bottom: 0;
            line-height: 1;
            opacity: 0.6;
            font-weight: lighter;
            font-family: "Gotham";
        }

        .ci__title {
            font-size: size(42);
            line-height: 1;
            text-align: center;
        }

        .ci__bg {
            object-fit: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -2;
            transition: $tr--slow;
        }
    }
}
