@import "../../../assets/scss/variables";

.Modal {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 250;

    .inner {
        margin-top: $topnav-height - 1em;
        background-color: theme-color("light");
        z-index: 120;
        position: absolute;
        left: 1em;
        right: 1em;
        padding: 1em 1.2em;
        box-shadow: 0 5px 3px rgba(black, 0.1);
        margin-bottom: 100px;

        @include media-breakpoint-up(md) {
            left: inherit;
            right: inherit;
            position: relative;
            width: 90%;
            max-width: 700px;
            margin-top: $topnav-height + 1em;
        }

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0.4em;

            .title {
                font-size: 1.2em;
                font-weight: bold;
                // font-style: italic;
                margin: 0;
                color: $primary;
                text-transform: uppercase;
            }
        }
    }

    .nav-tabs {
        .active {
            background: transparent;
            border-bottom: 1px solid theme-color("light");
        }
        .nav-link:not(.active) {
            background-color: rgba($ui-active--dark, 0.1);
        }

        .nav-link {
            margin-right: 0.2em;
        }
        .nav-link:hover:not(.active) {
            background-color: rgba($ui-active--dark, 0.2);
        }
    }
}
