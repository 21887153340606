@import "../../../assets/scss/variables";

.SubNav {
    position: relative;

    .icon {
        font-size: 1.2em;
        vertical-align: middle;
    }

    .list {
        display: none;
        position: absolute;
        background-color: $light;
        left: 0.8em;
        box-shadow: $sdw--light;

        .NavItem {
            font-weight: 500;
            font-family: "Gotham Narrow", sans-serif;
            border-bottom: 1px solid rgba($gray, 0.75);

            &:last-child {
                border-bottom: none;
            }
        }

        > * {
            display: block;
            color: $primary;
            padding: 0.5em 1em;
            white-space: nowrap;
        }
    }

    &:hover {
        .list {
            display: block;
        }
    }
}
