@import '../../../assets/scss/variables';

.Backdrop {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    overflow-y: scroll;
    background-color: rgba(black,0);
    top: 0;left: 0;
    z-index: 200;
    display: none;

    &.show {
        display: block;
        background-color: rgba(black,.15)
    }

    .interaction {
        position: fixed;width: 100%;height: 100%;top: 0;left: 0;
    }
}