@import "../../../assets/scss/variables";

.History {
    max-width: 1000px;
    margin: 1em auto 5em;
    padding: 0 1em;

    @include media-breakpoint-up(lg) {
        max-width: 1100px;
        padding: 0;
    }
    @include media-breakpoint-up(xl) {
        max-width: 1300px;
    }

    // Timeline Structure
    .history__item {
        display: grid;
        grid-template-columns: 1fr 9fr;
        grid-template-rows: auto;

        @include media-breakpoint-up(md) {
            grid-template-columns: 5fr 1fr 5fr;
            grid-template-rows: auto;
        }

        .history__timeline {
            grid-column: 1 / 2;

            @include media-breakpoint-up(md) {
                grid-column: 2 / 3;
            }
        }

        .history__card {
            grid-column: 2 / 3;
            grid-row: 1;
        }

        @include media-breakpoint-up(md) {
            .history__card {
                grid-column: 1 / 2;
            }
            &:nth-child(odd) {
                .history__card {
                    grid-column: 3 / 4;
                }
            }
        }
    }

    // Card structure
    .history__card {
        display: flex;
        padding-bottom: 3em;
        flex-direction: row-reverse;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .history__image {
            flex: 0 0 35%;

            @include media-breakpoint-up(lg) {
                flex: 0 0 45%;
            }
        }
    }

    // Card design
    .history__title {
        font-family: "Gotham Narrow";
        color: $primary;
        margin-bottom: 0.5em;
        font-size: 1.6em;
    }

    .history__description {
        font-size: 0.9em;
        line-height: 1.2;
    }

    .history__image,
    .history__info {
        padding: 0 1em 1em;
    }

    // Timeline Design
    .history__timeline {
        position: relative;

        .history__year {
            // TODO Dynamic colors
            background-color: $secondary;
            border-radius: 5px;
            padding: 0.5em;
            font-size: 1em;
            font-family: "Gotham Narrow";
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin: 0;

            @include media-breakpoint-up(sm) {
                margin: 0 1em;
            }
        }

        $line_weight: 4px;
        .history__line {
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            border-left: $line_weight solid;
            z-index: 0;
            margin-left: ($line_weight/2) * -1;
        }
    }

    // History Index
    .history-index {
        display: flex;
        justify-content: center;
        margin: 1em 0 4em;

        .history-index__year {
            font-size: 1em;
            width: 4em;
            padding: 0.5em;
            text-align: center;
            color: #fff;
            font-family: "Gotham Narrow";
            cursor: pointer;
            display: block;
        }
    }
}
