@import "../../../../assets/scss/variables";

.BusinessInner {
    // ========================================================================
    // Intro
    // ========================================================================
    .IntroContainer {
        display: flex;
        pointer-events: none;
        position: relative;
        margin-top: 2em;

        z-index: 1;

        @include media-breakpoint-up(sm) {
            background: none;
            align-items: center;
        }

        @include media-breakpoint-up(md) {
            margin-top: -3em;
        }

        .image {
            position: absolute;
            width: 120px;
            z-index: 0;

            left: -75px;
            opacity: 0.4;

            @include media-breakpoint-up(sm) {
                opacity: 1;
                position: static;
                display: block;
                width: col-md(3);
                flex: 3;
            }
        }

        .info {
            flex: 12;
            font-size: size(16);
            pointer-events: all;
            padding: 4em 0 2em;
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(sm) {
                padding: 1em;
                font-size: size(18);
            }
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }

    // ========================================================================
    // List
    // ========================================================================
    .ListContainer {
        position: relative;
        padding-top: 5em;
        margin-bottom: 10em;

        @include media-breakpoint-up(sm) {
            height: 600px;

            margin-bottom: 0em;
            // margin-top: 4em;
            // display: flex;
            // align-items: center;

            > .inner {
                display: grid;
                grid-template-columns: repeat(15, 1fr);
                grid-template-rows: 1fr;
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 10em;
        }

        .info {
            grid-column: 1 / 10;
            grid-row: 1;
            position: relative;
            z-index: 10;
        }

        .image {
            position: absolute;
            right: -300px;
            top: -140px;
            opacity: 0.4;

            img {
                object-fit: contain;
            }

            @include media-breakpoint-up(sm) {
                right: 0;
                top: 0;
                opacity: 1;
                grid-column: 7 / 16;
                grid-row: 1;
                position: relative;
                margin-top: 4em;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    // ========================================================================
    // Form
    // ========================================================================
    .ActionContainer {
        padding: size(80) 0 size(140);
        background: linear-gradient(0deg, rgba(#f1ebe2, 0) 0%, rgba(#eceff1, 1) 66%);

        @include media-breakpoint-up(sm) {
            padding: size(50) 0 size(140) 0;
        }

        .section-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1em;
            margin-bottom: 0.5em;

            .section {
                text-align: center;
                margin-bottom: 0;
                position: relative;
                display: inline-block;
            }

            i {
                display: block;
                width: 4em;
                margin: 1em;
                height: 2px;
                border-bottom: 1px solid black;
            }
        }

        .title {
            font-size: size(28);
            color: $darker;
            text-align: center;
            padding-bottom: 1em;

            @include media-breakpoint-up(sm) {
                font-size: size(42);
            }
        }

        .sub-title {
            font-size: size(14);
            font-weight: 300;
            text-align: center;
            padding: 0 1em 1em;
            border-bottom: 1px solid #b0bec5;
            color: $primary;
            margin-bottom: 1em;

            @include media-breakpoint-up(sm) {
                font-size: size(21);
            }
        }

        .FormContainer {
            background-color: #fff;
            border-radius: 0.5em;
            box-shadow: 0 6px 4px -2px rgba($primary, 0.2);

            padding: 1.5em;
            margin: 0 -1.5em;
            max-width: 880px;

            @include media-breakpoint-up(sm) {
                margin: 0 auto;
                padding: 2em;
            }

            // Overrides
            .submit {
                border-radius: 0.2em;
            }

            .form__error {
                @include media-breakpoint-up(lg) {
                    width: 75%;
                    margin: 0 auto 1em;
                }
            }
        }
    }

    // .form__recaptcha {
    //     justify-content: flex-start;
    // }

    // ========================================================================
    // Maps
    // ========================================================================
    .MapContainer {
        $bp: sm;

        margin-bottom: 3em;
        max-width: 1400px;
        margin: -3em auto 3em;

        @include media-breakpoint-up(md) {
            margin: 0 auto 3em;
        }

        .title {
            text-transform: uppercase;
            font-size: size(18);
            font-weight: 300;
            padding: 1em;
            text-align: center;

            @include media-breakpoint-up($bp) {
                padding: 1em;
            }

            @include media-breakpoint-up(md) {
                font-size: size(26);
            }
        }

        .inner {
            @include media-breakpoint-up($bp) {
                display: flex;
                align-items: flex-end;
            }

            > div {
                flex: 1;
                padding: 1em 2em;
                // background: $theme-consumidor;
                background: darken($light, 1);
                box-shadow: 0 10px 6px -4px rgba($primary, 0.2);
                margin-bottom: 2em;

                @include media-breakpoint-up($bp) {
                    padding: 1em;
                }
            }

            > div:first-child {
                // background: $primary;
                background: lighten($light, 3);

                @include media-breakpoint-up($bp) {
                    margin-right: 1em;
                }
            }

            iframe {
                max-height: 300px;

                @include media-breakpoint-up($bp) {
                    max-height: 500px;
                }
            }
        }
    }
}

// ========================================================================
// Theming
// ========================================================================
.theme-almacenes {
    i {
        border-color: $theme-almacenes !important;
    }
}
.theme-consorcios {
    i {
        border-color: $theme-consorcios !important;
    }
}
.theme-consumidor {
    i {
        border-color: $theme-consumidor !important;
    }
}
