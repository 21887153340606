@import "../../../assets/scss/variables";

@keyframes rotating {
    0% {
        transform: rotate(0deg);
        opacity: 0.8;
    }
    100% {
        transform: rotate(360deg);
        opacity: 0.8;
    }
}

.PreLoader {
    width: 100%;
    height: 75vh;
    top: 0;
    left: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        font-size: 10em;
        color: $dark-blue;
        animation: rotating 2s linear infinite;
    }
}
