$base-size: 16;

body,
html {
  background-color: #fff;
}

html {
  font-size: $base-size - 4px;

  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    font-size: $base-size - 2px;
  }

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    font-size: $base-size * 1px;
  }

  // @media screen and (min-width: map-get($grid-breakpoints, xl)) {
  //   font-size: $base-size + 2px;
  // }
}

$font-family-sans-serif: "Roboto", sans-serif;
$font-family-cursive: "Gotham Narrow", sans-serif;
$headings-font-family: "Gotham", sans-serif;
$btn-font-family: $headings-font-family;

@function size($target, $context: $base-size) {
  @return ($target / $context) * 1em;
}

//.font-cursive {
//  font-family: $font-family-cursive;
//}
