@import "../../../assets/scss/variables";

.SideDrawer {
  position: fixed;
  top: 0;
  padding-top: $topnav-height * 1.2;
  bottom: 0;
  right: -100%;
  z-index: 100;
  background-color: theme-color("light");
  box-shadow: $sdw--light;

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  width: 80%;
  max-width: 640px;

  .top {
    // padding: 0 1em;
    font-size: 1.2em;
    font-weight: bold;
    flex: 10 1 auto;

    @include media-breakpoint-up(md) {
      font-size: 1.6em;
    }

    .NavItem,
    .BtnCollapse .toggler {
      padding: 1em 1.2em;
      display: flex;
      justify-content: space-between;
      font-size: 0.8em;
      text-transform: uppercase;
      font-family: "Gotham", sans-serif;
      border-bottom: $gray 1px solid;
      line-height: 1.2;

      @include media-breakpoint-up(md) {
        padding: 0.4em 0.6em;
      }
    }

    .icon {
      font-size: 1.4em;
    }
  }

  .bottom {
    flex: 1 1 auto;
    background-color: $primary;
    color: $light;
  }

  // .Footer {
  //   max-width: 285px;
  // }

  .NavData {
    padding: 1em 0.5em;
    font-size: 1.4em;

    .TextIcon {
      margin-bottom: 1.2em;
    }
  }

  .SocialNav {
    padding: 0 0.5em;
    .icon {
      padding: 0 0.5em;
      font-size: 2em;
      opacity: 0.5;
    }
  }
}
