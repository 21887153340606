@import "../../../assets/scss/variables";

.Form {
    @keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(360deg);
        }
    }
    .now-loading {
        -webkit-animation: spin 3s linear infinite;
        -moz-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }

    .form-control-file {
        padding: 0.6em !important;
        height: 3.2em;
    }

    .full-width {
        @include media-breakpoint-up(lg) {
            grid-column: 1 / 3;
        }
    }
}
