@import "../../../assets/scss/variables";

.Services {
    background-color: $darker;
    width: 100%;
    position: relative;
    margin-top: -1em;

    .card-wrapper {
        display: flex;
        justify-content: space-around;
        padding: 0 1em;
        max-width: 1400px;
        margin: 0 auto;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    .Card {
        @include media-breakpoint-up(md) {
            margin-top: -1em;
            max-width: 23%;
            box-shadow: $sdw--light;
        }
    }
}
