@import "../../../assets/scss/variables";

.SectionInfo {
    .map {
        border: none;
        // box-shadow: $sdw--light;
        margin-bottom: 1em;
    }

    .info__images .BtnIconBox {
        max-width: none;
    }
}

.Distribuidores {
    .info__inner {
        flex-direction: column;
    }
}

.Clientes {
    .info__inner {
        display: block;

        @include media-breakpoint-up(lg) {
            .info__main {
                max-width: 1100px;
            }
        }
    }

    .info__images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 3em;
        gap: 3em;
        grid-template-rows: auto;
        max-width: 1100px;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
