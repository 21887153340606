@import "../../assets/scss/variables";

// * { outline: red 1px solid; }

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.Layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .PageTitle {
        padding-left: $info-left;
        font-size: size(24);
        margin: size(40) 0 size(20) 0;
        position: relative;
        color: $dark;

        @include media-breakpoint-up(md) {
            padding-left: $info-left-md;
            font-size: size(34);
            padding-right: 2em;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1300px;
            padding-left: 0.5em;
            margin-left: auto;
            margin-right: auto;
            padding-right: 4em;
        }

        &:after {
            content: "";
            width: col(6);
            border-bottom: #b0bec5 2px solid;
            position: absolute;
            bottom: size(5) * -1;
            left: 0;

            @include media-breakpoint-up(md) {
                width: col-md(4);
            }

            @include media-breakpoint-up(xl) {
                left: col-md(4) * -1;
                width: col-md(8);
            }
        }
    }

    .main-block {
        padding: 0 col(0.5);

        @include media-breakpoint-up(md) {
            padding: 0 col-md(1);
        }

        @include media-breakpoint-up(lg) {
            padding: 0 2em !important;
            margin: 0 auto;
            max-width: 1300px;
        }
    }

    .main-block--padding {
        padding: 0 $info-left 3em $info-left;
        @include media-breakpoint-up(md) {
            padding: 0 $info-left-md 3em $info-left-md;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 3em 3em $info-left-md;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 2em 3em 2em !important;
            margin: 0 auto;
            max-width: 1300px;
        }
    }
}

// UI Blocks
.slider-wrapper {
    position: relative;

    .sliderPager {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        bottom: 50%;
        margin-bottom: -1.5em;
        pointer-events: none;

        .arrow {
            height: 1.5em;
            width: 1.5em;
            border: 0;
            border-radius: 4em;
            font-size: 2em;
            box-shadow: $sdw--light;
            transition: $tr--fast;
            position: relative;
            pointer-events: all;

            &:active,
            &:focus {
                outline: none;
                background-color: #fff;
            }

            &:first-child {
                left: -0.75em;
            }
            &:last-child {
                right: -0.75em;
            }
        }

        .arrow:hover {
            background-color: #fff;
        }
    }
}

.Pagination {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin: 1px;
    padding: 0;
    align-items: center;
    margin: size(50) 0 size(70) 0;

    li {
        margin: 0.4em;
    }

    $pager-size: 42;
    a {
        display: block;
        border: 1px solid $dark-blue;
        background: $light;
        border-radius: 100%;
        font-family: "Gotham", sans-serif;
        font-size: size(17);
        font-weight: bolder;
        height: size($pager-size);
        width: size($pager-size);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        transition: $tr--fast;
    }

    .active {
        a {
            background-color: $primary;
            color: $light;
        }
    }

    li:not(.active) a:hover {
        background-color: #fff;
    }
}

// Misc
.check-list {
    list-style-image: url("../../assets//images//checkmark.svg");
}

.btn-outline {
    background: transparent;
    border: 1px solid;
    font-family: "Gotham Narrow", sans-serif;
}

.btn-outline--02 {
    background: transparent;
    border: 1px solid;
    font-size: size(14);
    font-family: "Gotham", sans-serif;
}

.btn-circle {
    border: none;
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}
