@import "../../../assets/scss/variables";
.Logo {
  display: flex;
  align-items: center;
  width: 11em;
  height: 100%;
  padding: 0 1.2em 0 1em;
  cursor: pointer;
  background-color: $light;

  @include media-breakpoint-up(md) {
    width: 11em;
  }
}