@import "../../../assets/scss/variables";

.NewsPage {
    .date {
        margin: 1.5em 0 0.5em;
        opacity: 0.7;

        @include media-breakpoint-up(md) {
            margin: 0 0 0.5em 0;
        }
    }
}
