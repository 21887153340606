@import "../../../assets/scss/variables";

.Footer {
    background-color: $primary;
    color: $dark-blue;

    .inner {
        flex-direction: column-reverse;
        display: flex;
        max-width: 1300px;
        margin: 0 auto;
        padding: 1em 2em;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: row;
            padding: 1em 0;
        }
    }

    .Col {
        padding: 1em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: rgba($dark-blue, 0.5) solid 1px;
        flex-basis: 100%;

        @include media-breakpoint-up(md) {
            border-right: rgba($dark-blue, 0.5) solid 1px;
            border-top: none;
            padding: 0 2em;
            margin: 1em 0;
        }

        &:last-child {
            border: none;
        }

        .icon-mail {
            margin-right: 0.4em;

            @include media-breakpoint-up(md) {
                margin-right: 0;
            }
        }
    }

    .col-h {
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    .btn-circle {
        font-size: 1.8em;
        width: 3em;
        height: 3em;
    }

    .social-wrapper {
        margin-top: 1em;
    }

    .social__icons {
        display: flex;
        justify-content: space-between;
    }

    .SocialNav {
        .icon {
            font-size: 2em;
            margin-right: 0.5em;

            @include media-breakpoint-up(md) {
                font-size: 1.5em;
            }
        }
    }

    a {
        transition: $tr--fast;
        &:hover {
            color: #fff;
        }
    }

    .copyright {
        opacity: 0.7;
        padding-bottom: 1em;

        p {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
