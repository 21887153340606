@import "../../../assets/scss/variables";

.ProductList {
    .slide {
        width: 100%;
        position: relative;
        display: flex;
        color: $light;
        overflow: hidden;

        .slide__bg {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center;
            // max-height: 650px;
            // position: absolute;
            position: relative;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }

    .slick-dots {
        bottom: 1em;
        button:before {
            color: $primary;
            font-size: 10px;
        }
    }

    .main-block {
        @include media-breakpoint-up(xl) {
            padding: 0 col-md(2);
        }
    }

    .list-inner {
        display: grid;
        grid-template-rows: auto;
        gap: 1em 1em;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .ProductItem {
        background: lighten($light, 2);
        border-radius: 5px;
        box-shadow: $sdw--light;
        padding: 1em;

        .product__inner {
            align-items: center;
            display: flex;
        }

        // @include media-breakpoint-up(md) {
        //     display: flex;
        // }

        .product__image {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 30%;
            margin-bottom: 1em;
            mix-blend-mode: darken;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
        .product__info {
            flex-grow: 2;
            flex-basis: 60%;
            padding-left: 1em;
            line-height: 1.2;

            .title {
                color: $primary;
                font-size: size(24);
                font-weight: bold;
                line-height: 1;
                margin-bottom: 0.8em;
            }

            .sub-title {
                font-size: size(21);
                font-weight: 300;
                line-height: 1;
            }

            .short-description {
                font-weight: 300;
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            .product__features {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 1em;
                    font-weight: normal;
                }

                ul {
                    padding-left: 1.2em;
                    margin: 0;
                }
            }

            .product__footer {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                font-weight: 300;
                flex-wrap: wrap;

                .pdf {
                    color: $primary;
                    font-size: 2em;
                    vertical-align: middle;
                    margin-right: 0.5em;
                }
            }

            .btn-outline {
                .icon {
                    font-size: 0.8em;
                    margin-left: 0.5em;
                }
            }
        }
    }
}
