@import "../../../assets/scss/variables";

.TextIcon {
    font-size: 0.75em;
    display: flex;
    align-items: center;
    line-height: 1;

    .icon {
        padding: 0 1em;
        display: inline-block;
        align-self: center;
    }

    &.big {
        font-size: size(21);
        font-family: 'Gotham';
    }
}
