@import "../../../assets/scss/variables";

$innerBreakpoint: lg;
.Contact {
    .contact__inner {
        @include media-breakpoint-up($innerBreakpoint) {
            display: grid;
            grid-template-columns: 12fr 6fr;
            grid-template-rows: none;
        }
    }

    .Form {
        form {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            gap: 1em;

            @include media-breakpoint-up($innerBreakpoint) {
                grid-template-columns: 1fr 1fr;

                .textarea {
                    grid-column: 1 / 3;
                }
            }

            input,
            textarea {
                padding: 1.6em 1.2em;
            }

            .hint {
                font-size: size(12);
                margin-bottom: 0;
                padding: 0.5em 0 0 1em;
                line-height: 1;
            }

            .submit {
                border-radius: 0;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;
                // color: $primary;
                // border-color: $primary;
                font-family: "Gotham", sans-serif;
                font-weight: 600;
                padding: 1.5em;
                line-height: 1;

                .text {
                    text-align-last: left;
                }

                @include media-breakpoint-up($innerBreakpoint) {
                    padding: 0.6em 1.4em;
                }

                .icon {
                    font-size: 2em;
                }
            }

            .form__error {
                margin-bottom: 0;

                @include media-breakpoint-up($innerBreakpoint) {
                    grid-column: 1 / 3;
                }
            }

            .form__recaptcha div {
                display: flex;
                justify-content: center;

                @include media-breakpoint-up($innerBreakpoint) {
                    justify-content: flex-end;
                }
            }

            .sended {
                display: flex;
                align-items: center;
                padding-right: 1em;

                .icon {
                    font-size: 1.5em;
                    padding-left: 1em;
                }
            }

            .required-notice {
                opacity: 0.75;
                font-size: size(16);
                margin-top: 0.5em;
                text-align: center;
                margin-bottom: 0;

                @include media-breakpoint-up($innerBreakpoint) {
                    font-size: size(14);
                    margin-top: 0.2em;
                    text-align: left;
                }
            }
        }
    }

    .contact__sidebar {
        background: $light;
        padding: 2em;
        color: $dark;
        margin-top: 1em;

        @include media-breakpoint-up($innerBreakpoint) {
            margin-left: 2em;
            margin-top: 0;
        }

        .IconColumn {
            margin-bottom: 1em;

            &:last-child {
                margin-top: 2em;
                margin-bottom: 0;
            }
        }
    }

    .map {
        border: none;
        // box-shadow: $sdw--light;
        margin-bottom: 1em;
    }

    // Checkbox
    .form-check {
        color: $gray--dark;
        strong {
            color: $primary;
        }

        input {
            width: 1em;
            height: 1em;
        }
    }
}
