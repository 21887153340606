@import "../../../assets/scss/variables";

$mobileBp: lg;
.TopNav {
    height: $topnav-height;
    background-color: $primary;
    color: $light;
    z-index: 110;
    position: relative;
    box-shadow: $sdw--light;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(xl) {
        font-size: 1.1em;
    }

    > .start {
        height: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .MainNav {
        display: none;
        align-items: center;
        margin-left: 1em;
        position: relative;
        z-index: 100;

        @include media-breakpoint-up($mobileBp) {
            display: flex;
        }

        > .NavItem,
        .SubNav > .NavItem {
            margin-left: 0.8em;
            vertical-align: middle;
            line-height: 2;
            padding: 0.2em 0.5em;
        }

        .NavItem {
            text-transform: uppercase;
            font-family: "Gotham";
            font-weight: bold;
            font-size: size(12);
            transition: $tr--fast;
            // border-radius: 4px;

            &:hover {
                color: inherit;
                background-color: $dark;
            }
        }
    }

    > .end {
        padding-right: 1em;
        display: flex;
        align-items: center;

        @include media-breakpoint-up($mobileBp) {
            padding-right: 1.5em;
        }

        .NavData {
            display: flex;
        }

        .TextIcon {
            display: none;
            margin-right: 0.5em;

            @include media-breakpoint-up($mobileBp) {
                display: block;
            }
        }

        .BtnIcon {
            color: $light;
            font-size: 1.5em;

            @include media-breakpoint-up($mobileBp) {
                font-size: 1.2em;
                margin-left: 1em;
            }
        }
    }
}
