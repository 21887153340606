@import "../../../assets/scss/variables";

.News {
    .no-results {
        font-size: 1.2em;
        padding-left: col(1);

        @include media-breakpoint-up(md) {
            padding-left: col-md(1);
        }
    }

    // .NewsItem {
    //     .inner {
    //         flex-wrap: wrap;

    //         > a {
    //             order: 2;
    //         }
    //     }

    //     .ni__info {
    //         width: 100% !important;
    //         padding: 1em;
    //         order: 1;
    //     }

    //     .ni__image {
    //         width: 85% !important;
    //         padding: 1em;

    //         @include media-breakpoint-up(md) {
    //             width: col-md(6);
    //             // padding: 1em 0 0 1em;
    //         }
    //     }

    //     .ni__actions {
    //         width: 15% !important;
    //         order: 3;
    //     }
    // }
}
