@import "../../../assets/scss/variables";

.WithTitle {
    padding-top: 3em;

    .description {
        padding-left: $info-left;
        font-size: 1em;
        margin-bottom: 2em;
        // max-width: 1000px;
        padding-right: 1em;
        line-height: 1.2;
        font-family: "Gotham";
        margin-top: -0.5em;

        @include media-breakpoint-up(md) {
            font-size: 1.4em;
            padding-left: $info-left-md;
            padding-right: 3em;
            margin-bottom: 3em;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    .section {
        padding-left: $info-left;
        font-size: size(16);
        color: $dark;
        margin-bottom: 0;
        font-weight: 300;
        line-height: 1;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            padding-left: $info-left-md;
            font-size: size(22);
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1300px;
            margin: 0 auto;
            padding-left: 1em;
        }
    }

    .PageTitle {
        padding-top: 0;
        margin-top: 0;
        line-height: 1.1;
        text-transform: uppercase;
        font-weight: 300;
        font-size: size(24);

        display: flex;
        align-items: center;

        .btn-circle {
            font-size: 0.6em;
            margin-right: 0.5em;
            display: flex;
            align-content: center;
            margin: 0.3em 0.5em 0.3em 0;

            padding: 0.2em;
        }

        @include media-breakpoint-up(md) {
            font-size: size(42);
        }

        strong {
            font-weight: bold;
        }
    }
}
